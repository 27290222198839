import { FiInfo } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import useSWR from 'swr';
import { useFormat } from 'helpers/hooks/useFormat';
import { fetchApiHub, revalidateOptions } from 'frontastic';

export const SendResetCode = ({
  resetUsername,
  toForgotUsername,
  otpSentAlert,
  retrieve,
  error,
  verificationCodeSent,
  sendCodeClicked,
  receivedOTP,
  setReceivedOTP,
  handleSendCodeClick,
  inputRef,
  handleInputFocus,
  handleInputBlur,
  resetRadioButtons,
  retrieveOTPcode,
  toForgotPassword,
  isUserLoggedIn,
  handleLogoutAndCloseModal,
  authretrieveOTPcode,
  handleOtpRequestForLoggedInUser,
}) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const DisplayStoreNotFoundMsg = () => {
    const queryParams = new URLSearchParams({
      messageKey: retrieve?.isEmail ? 'codeSentToEmail' : 'codeSentToNumber',
    });
    const { data } = useSWR(`/action/customObjects/getCustomMessage?${queryParams}`, fetchApiHub, revalidateOptions);

    return (
      <section className="my-2" aria-live="assertive">
        <p className="text-md font-bold text-blue-900">{data}</p>
      </section>
    );
  };

  return (
    <div>
      {!isUserLoggedIn ? (
        <div className="mb-8">
          <h3 className="absolute top-10 text-lg font-semibold text-black-900">
            {formatAccountMessage({
              id: resetUsername ? 'Forgot.username' : 'Forgot.password',
              defaultMessage: resetUsername ? 'Retrieve Username' : 'Reset Password',
            })}
          </h3>
        </div>
      ) : (
        <div className="mb-8">
          <h3 className="absolute top-10 text-lg font-semibold text-black-900">
            {formatAccountMessage({
              id: 'Two.FA',
              defaultMessage: '2F Authentication',
            })}
          </h3>
        </div>
      )}

      <button
        type="button"
        className="absolute right-6 top-10"
        onClick={() => {
          if (isUserLoggedIn) {
            resetRadioButtons();
            handleLogoutAndCloseModal();
          } else {
            toForgotUsername();
          }
        }}
        aria-label="Close"
      >
        <GrClose />
      </button>
      <div className="text-center">
        <div className="flex-col py-10 text-center">
          <h3 className="mb-4 text-lg font-semibold text-black-900">
            {formatAccountMessage({
              id: 'please.enter.verification.code',
              defaultMessage: 'Please enter your verification code?',
            })}
          </h3>
          {otpSentAlert && <DisplayStoreNotFoundMsg />}
          <p id="verification-code-description">
            {formatAccountMessage({
              id: 'verification.code',
              defaultMessage:
                'The code is valid for ten minutes. If you did not receive a code, please click "Send New Code". ',
            })}
          </p>
        </div>
        {error && (
          <section className="my-2" aria-live="assertive">
            <p className="text-sm text-red-900" id="error-message">
              {error}
            </p>
          </section>
        )}
        {verificationCodeSent && sendCodeClicked && !error && (
          <p className="text-sm text-red-900" id="verification-code-sent-message">
            {formatAccountMessage({
              id: 'newCodeSent',
              defaultMessage: 'New Code Sent',
            })}
          </p>
        )}

        <div className=" relative flex justify-center ">
          <input
            type="number"
            className={`w-3/4 rounded-sm border pl-1.5 focus:border ${
              verificationCodeSent ? 'border-[#dd001c]' : 'border-gray-600'
            } `}
            required
            value={receivedOTP || ''}
            onChange={(e) => setReceivedOTP(e.target.value)}
            aria-describedby="verification-code-description error-message"
            ref={inputRef}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {verificationCodeSent && sendCodeClicked && !error && (
            <div className="absolute right-14 top-2">
              <FiInfo className=" text-[#dd001c]" />
            </div>
          )}
        </div>
      </div>
      <div className="pt-14">
        <button
          className="w-full rounded-sm bg-blue-900 px-4 py-2 text-white disabled:bg-gray-200"
          disabled={receivedOTP === ''}
          onClick={() => {
            if (isUserLoggedIn) {
              authretrieveOTPcode();
            } else {
              retrieveOTPcode();
              setReceivedOTP('');
            }
          }}
          type="button"
        >
          Continue
        </button>
      </div>
      <div className="pt-6 text-center">
        <button
          className="w-1/4 text-accent-400"
          onClick={() => {
            if (isUserLoggedIn) {
              resetRadioButtons();
              handleLogoutAndCloseModal();
            } else if (resetUsername) {
              toForgotUsername();
            } else {
              toForgotPassword();
            }
            resetRadioButtons();
            setReceivedOTP('');
          }}
          type="button"
        >
          {formatAccountMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
        </button>
      </div>
      <div className="pt-6">
        <button
          className="w-full rounded-sm border border-gray-600 bg-gray-100 px-4 py-2 text-black-600"
          onClick={() => {
            if (isUserLoggedIn) {
              handleOtpRequestForLoggedInUser();
            } else {
              handleSendCodeClick;
            }
          }}
          type="button"
        >
          {formatAccountMessage({
            id: 'sendNewCode',
            defaultMessage: 'Send New Code',
          })}
        </button>
      </div>
    </div>
  );
};
